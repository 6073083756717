<template>
    <div v-if="countries" class="footer-location-dropdown">
        <span :class="$isBchollos ? 'text-white' : 'text-gray-800'"> Cambiar país: </span>

        <button ref="$button" class="footer-location-dropdown__button" @click="toggleDropdown">
            <img
                :src="$isBchollos ? $assets.white.earthAmerica : $assets.black.earthAmerica"
                alt="Globo terraqueo"
                class="eart"
            />
            <img width="12px" height="12px" :src="currentFlag" alt="flag" class="flag" />
            <img
                :src="$assets.gray.arrowDown"
                alt="icon"
                width="12px"
                height="12px"
                :class="{ top: showOptions }"
            />
        </button>

        <ul v-if="showOptions" ref="$list" class="footer-location-dropdown__options open-down" @click.stop>
            <li v-for="(option, index) in countries" :key="index">
                <a :href="option.url" rel="nofollow" target="_self">
                    <img :src="countriesFlags[option.variant]" alt="flag" />{{
                        countriesCodes[option.variant]
                    }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'

const { public: config } = useRuntimeConfig()

const props = defineProps({
    countries: Array as PropType<{ url: string; variant: 'megadescuentos' | 'bchollos' | 'jdescuentos' }[]>,
})

const showOptions = ref(false)

const $list = ref()
const $button = ref()

const countriesCodes = {
    bchollos: 'Bchollos',
    megadescuentos: 'Megadescuentos',
    jdescuentos: 'Jdescuentos',
} as { [x: string]: string }

const countriesFlags = {
    bchollos: '/assets/png/es.png',
    megadescuentos: '/assets/png/mx.png',
    jdescuentos: '/assets/png/cl.png',
} as { [x: string]: string }

const currentSite = computed(() => {
    return countriesCodes[config.variant.value] || 'México (MX)'
})
const currentFlag = computed(() => {
    return countriesFlags[config.variant.value] || countriesFlags.megadescuentos
})

const toggleDropdown = () => {
    showOptions.value = !showOptions.value
}

onClickOutside($list && $button, () => {
    showOptions.value = false
})
</script>

<style lang="postcss" scoped>
.footer-location-dropdown {
    @apply relative flex h-[26px] max-w-max items-center gap-x-2 text-nowrap text-sm font-medium text-gray-800;
    &__button {
        @apply flex h-full w-36 max-w-max items-center gap-x-1 text-xs;
        img {
            @apply h-2 w-2 transition-transform;

            &.eart {
                @apply h-5 w-5;
            }
            &.top {
                @apply -rotate-180 transform;
            }
            &.flag {
                @apply h-5 w-6 rounded-[2px];
            }
        }
        p {
            @apply flex-grow text-left;
        }
    }
    &__options {
        @apply absolute left-0 z-50 w-full min-w-max rounded border border-gray-200 bg-gray-100 p-[5px] text-left;
        li {
            a {
                @apply flex whitespace-nowrap rounded p-[5px] text-base font-normal lg:hover:bg-gray-200;
                img {
                    @apply mr-2 block h-5 w-6 rounded-[2px];
                }
            }
        }
        &.open-up {
            @apply bottom-full;
        }
        &.open-down {
            @apply top-full mt-1;
        }
    }
}
</style>
